import ArrowRightSort from "@/public/icons/ArrowRightSort.svg";
import ArrowRight from "@/public/icons/ArrowRight.svg";
import BlockchainSecurity from "@/public/icons/BlockchainSecurity.svg";
import DataSecurity from "@/public/icons/DataSecurity.svg";
import SecurityAudit from "@/public/icons/SecurityAudit.svg";
import SecurityCompliance from "@/public/icons/SecurityCompliance.svg";
import SecurityOperations from "@/public/icons/SecurityOperations.svg";
import { Button } from "antd";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";

export default function SectionFeatures() {
  const { t } = useTranslation("home");
  const [activeNavbar, setActiveNavbar] = useState("security_audit");
  const router = useRouter();

  const navbars = [
    {
      key: "security_audit",
      title: t("features.item_1.label"),
      icon: <SecurityAudit className="w-5 h-5" />,
    },
    {
      key: "security_operations",
      title: t("features.item_2.label"),
      icon: <SecurityOperations className="w-5 h-5" />,
    },
    {
      key: "data_security",
      title: t("features.item_3.label"),
      icon: <DataSecurity className="w-5 h-5" />,
    },
    {
      key: "security_compliance",
      title: t("features.item_4.label"),
      icon: <SecurityCompliance className="w-5 h-5" />,
    },
    {
      key: "blockchain_security",
      title: t("features.item_5.label"),
      icon: <BlockchainSecurity className="w-5 h-5" />,
    },
  ];

  const SecurityAuditContent = () => {
    return (
      <>
        <div className="flex flex-col justify-between flex-1 flex-shrink-0 p-8 pr-0 md:p-4">
          <div>
            <h3 className="text-dark-title text-[36px] lg:text-[28px] lg:leading-9 lg:mb-6 font-semibold leading-[48px] mb-12">
              {t("features.item_1.title")}
            </h3>
            <ul className="list-none flex flex-col gap-[10px] mb-8">
              <li className="group">
                <a
                  href="https://cystack.io"
                  target="_blank"
                  className="font-medium text-[#1890FF] leading-[22px]"
                >
                  <span className="underline">
                    {t("features.item_1.link_1")}
                  </span>
                  <ArrowRightSort className="w-[14px] h-[14px] pt-[2px] ml-[4px] group-hover:translate-x-1 transition-transform flex-shrink-0" />
                </a>
              </li>
              <li
                className={`group ${router.locale === "en" ? "pr-0" : "pr-20"}`}
              >
                <a
                  href="/services/penetration-testing"
                  target="_blank"
                  className="font-medium text-[#1890FF] leading-[22px]"
                >
                  <span className="underline">
                    {t("features.item_1.link_2")}
                  </span>
                  <ArrowRightSort className="w-[14px] h-[14px] pt-[2px] ml-[4px] group-hover:translate-x-1 transition-transform flex-shrink-0" />
                </a>
              </li>
              <li className="group">
                <a
                  href="/services/redteam"
                  target="_blank"
                  className="font-medium text-[#1890FF] leading-[22px]"
                >
                  <span className="underline">
                    {t("features.item_1.link_3")}
                  </span>
                  <ArrowRightSort className="w-[14px] h-[14px] pt-[2px] ml-[4px] group-hover:translate-x-1 transition-transform flex-shrink-0" />
                </a>
              </li>
              <li className="group">
                <a
                  href="/services/infrastructure-security-audit"
                  target="_blank"
                  className="font-medium text-[#1890FF] leading-[22px]"
                >
                  <span className="underline">
                    {t("features.item_1.link_4")}
                  </span>
                  <ArrowRightSort className="w-[14px] h-[14px] pt-[2px] ml-[4px] group-hover:translate-x-1 transition-transform flex-shrink-0" />
                </a>
              </li>
              <li className="group">
                <a
                  href="/services/performance-testing"
                  target="_blank"
                  className="font-medium text-[#1890FF] leading-[22px]"
                >
                  <span className="underline">
                    {t("features.item_1.link_5")}
                  </span>
                  <ArrowRightSort className="w-[14px] h-[14px] pt-[2px] ml-[4px] group-hover:translate-x-1 transition-transform flex-shrink-0" />
                </a>
              </li>
            </ul>
          </div>
          <div className="flex items-center gap-6">
            <a
              href="https://cystack.net/customers/trusting-social-protects-the-integrity-of-the-evo-credit-card-payment-system"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image-blur">
                <img
                  src="/images/home/features/SecurityAudit-Logo1.svg"
                  className="max-h-[30px] object-contain"
                  alt="CyStack features"
                />
              </div>
            </a>
            <a
              href="https://cystack.net/customers/techhaus-from-vulnerability-to-victory-with-penetration-testing"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image-blur">
                <img
                  src="/images/home/features/SecurityAudit-Logo2.svg"
                  className="max-h-[30px] object-contain"
                  alt="CyStack features"
                />
              </div>
            </a>
            <a
              href="https://cystack.net/customers/epwise-ag-remarkable-efforts-in-mitigating-cyber-risks"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image-blur">
                <img
                  src="/images/home/features/SecurityAudit-Logo3.svg"
                  className="max-h-[30px] object-contain"
                  alt="CyStack features"
                />
              </div>
            </a>
          </div>
        </div>
        <img
          src="/images/home/features/SecurityAudit.svg"
          className="w-full lg:max-w-full xl:max-w-[530px] max-w-[630px] aspect-3/2 object-cover"
          alt="CyStack Security Audit"
        />
      </>
    );
  };
  const SecurityOperationsContent = () => {
    return (
      <>
        <div className="flex flex-col justify-between flex-1 flex-shrink-0 p-8 pr-0 md:p-4">
          <div>
            <h3 className="text-dark-title text-[36px] lg:text-[28px] lg:leading-9 lg:mb-6 font-semibold leading-[48px] mb-12">
              {t("features.item_2.title")}
            </h3>
            <ul className="list-none flex flex-col gap-[10px] mb-8">
              <li className="group">
                <a
                  href="/products/device-manager"
                  target="_blank"
                  className="font-medium text-[#1890FF] leading-[22px]"
                >
                  <span className="underline">
                    {t("features.item_2.link_1")}
                  </span>
                  <ArrowRightSort className="w-[14px] h-[14px] pt-[2px] ml-[4px] group-hover:translate-x-1 transition-transform flex-shrink-0" />
                </a>
              </li>
              <li className="group">
                <a
                  href="/services/vulnerability-management"
                  target="_blank"
                  className="font-medium text-[#1890FF] leading-[22px]"
                >
                  <span className="underline">
                    {t("features.item_2.link_2")}
                  </span>
                  <ArrowRightSort className="w-[14px] h-[14px] pt-[2px] ml-[4px] group-hover:translate-x-1 transition-transform flex-shrink-0" />
                </a>
              </li>
              <li className="group">
                <a
                  href="/services/security-monitoring"
                  target="_blank"
                  className="font-medium text-[#1890FF] leading-[22px]"
                >
                  <span className="underline">
                    {t("features.item_2.link_3")}
                  </span>
                  <ArrowRightSort className="w-[14px] h-[14px] pt-[2px] ml-[4px] group-hover:translate-x-1 transition-transform flex-shrink-0" />
                </a>
              </li>
              <li className="group">
                <a
                  href="/services/incident-response"
                  target="_blank"
                  className="font-medium text-[#1890FF] leading-[22px]"
                >
                  <span className="underline">
                    {t("features.item_2.link_4")}
                  </span>
                  <ArrowRightSort className="w-[14px] h-[14px] pt-[2px] ml-[4px] group-hover:translate-x-1 transition-transform flex-shrink-0" />
                </a>
              </li>
              <li className="group">
                <a
                  href="https://whitehub.net/"
                  target="_blank"
                  className="font-medium text-[#1890FF] leading-[22px]"
                >
                  <span className="underline">
                    {t("features.item_2.link_5")}
                  </span>
                  <ArrowRightSort className="w-[14px] h-[14px] pt-[2px] ml-[4px] group-hover:translate-x-1 transition-transform flex-shrink-0" />
                </a>
              </li>
            </ul>
          </div>
          <div className="flex items-center gap-6">
            <a
              href="https://cystack.net/customers/opencommerce-group-extraordinary-journey-through-vulnerability-remediation"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image-blur">
                <img
                  src="/images/home/features/SecurityOperations-Logo1.svg"
                  className="max-h-[30px] object-contain"
                  alt="CyStack features"
                />
              </div>
            </a>
            <a
              href="https://cystack.net/customers/how-one-mount-group-uses-managed-bug-bounty-for-application-protection"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image-blur">
                <img
                  src="/images/home/features/SecurityOperations-Logo2.svg"
                  className="max-h-[30px] object-contain"
                  alt="CyStack features"
                />
              </div>
            </a>
            <a
              href="https://cystack.net/customers/antsomi-proactively-responds-to-security-threats"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image-blur">
                <img
                  src="/images/home/features/SecurityOperations-Logo3.svg"
                  className="max-h-[30px] object-contain"
                  alt="CyStack features"
                />
              </div>
            </a>
          </div>
        </div>
        <img
          src="/images/home/features/SecurityOperations.svg"
          className="w-full lg:max-w-full xl:max-w-[530px] max-w-[630px] aspect-3/2 object-cover"
          alt="CyStack Security Operations"
        />
      </>
    );
  };
  const DataSecurityContent = () => {
    return (
      <>
        <div className="flex flex-col justify-between flex-1 flex-shrink-0 p-8 pr-0 md:p-4">
          <div>
            <h3 className="text-dark-title text-[36px] lg:text-[28px] lg:leading-9 lg:mb-6 font-semibold leading-[48px] mb-12">
              {t("features.item_3.title")}
            </h3>
            <ul className="list-none flex flex-col gap-[10px] mb-8">
              <li className="group">
                <a
                  href="https://locker.io/passwords"
                  target="_blank"
                  className="font-medium text-[#1890FF] leading-[22px]"
                >
                  <span className="underline">
                    {t("features.item_3.link_1")}
                  </span>
                  <ArrowRightSort className="w-[14px] h-[14px] pt-[2px] ml-[4px] group-hover:translate-x-1 transition-transform flex-shrink-0" />
                </a>
              </li>
              <li
                className={`group ${router.locale === "en" ? "pr-0" : "pr-10"}`}
              >
                <a
                  href="https://locker.io/passwords"
                  target="_blank"
                  className="font-medium text-[#1890FF] leading-[22px]"
                >
                  <span className="underline">
                    {t("features.item_3.link_2")}
                  </span>
                  <ArrowRightSort className="w-[14px] h-[14px] pt-[2px] ml-[4px] group-hover:translate-x-1 transition-transform flex-shrink-0" />
                </a>
              </li>
              <li
                className={`group ${router.locale === "en" ? "pr-0" : "pr-20"}`}
              >
                <a
                  href="https://locker.io/secrets"
                  target="_blank"
                  className="font-medium text-[#1890FF] leading-[22px]"
                >
                  <span className="underline">
                    {t("features.item_3.link_3")}
                  </span>
                  <ArrowRightSort className="w-[14px] h-[14px] pt-[2px] ml-[4px] group-hover:translate-x-1 transition-transform flex-shrink-0" />
                </a>
              </li>
              <li className="group">
                <a
                  href="/products/data-leak-detection"
                  target="_blank"
                  className="font-medium text-[#1890FF] leading-[22px]"
                >
                  <span className="underline">
                    {t("features.item_3.link_4")}
                  </span>
                  <ArrowRightSort className="w-[14px] h-[14px] pt-[2px] ml-[4px] group-hover:translate-x-1 transition-transform flex-shrink-0" />
                </a>
              </li>
            </ul>
          </div>
          <div className="flex items-center gap-6">
            <a
              href="https://cystack.net/customers/vincss-decoding-tenacious-roadmap-to-passwordless-with-locker-password-manager"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image-blur">
                <img
                  src="/images/home/features/DataSecurity-Logo1.svg"
                  className="max-h-[30px] object-contain"
                  alt="CyStack features"
                />
              </div>
            </a>
          </div>
        </div>
        <img
          src="/images/home/features/DataSecurity.svg"
          className="w-full lg:max-w-full xl:max-w-[530px] max-w-[630px] aspect-3/2 object-cover"
          alt="CyStack Data Security"
        />
      </>
    );
  };
  const SecurityComplianceContent = () => {
    return (
      <>
        <div className="flex-1 flex-shrink-0 p-8 pr-0">
          <h3 className="text-dark-title text-[36px] lg:text-[28px] lg:leading-9 lg:mb-6 font-semibold leading-[48px] mb-12">
            {t("features.item_4.title")}
          </h3>
          <ul className="list-none flex flex-col gap-[10px] mb-8">
            <li className="group">
              <a
                href="/services/policy-development"
                target="_blank"
                className="font-medium text-[#1890FF] leading-[22px]"
              >
                <span className="underline">{t("features.item_3.link_1")}</span>
                <ArrowRightSort className="w-[14px] h-[14px] pt-[2px] ml-[4px] group-hover:translate-x-1 transition-transform flex-shrink-0" />
              </a>
            </li>
            <li className="group">
              <a
                href="/services/security-training"
                target="_blank"
                className="font-medium text-[#1890FF] leading-[22px]"
              >
                <span className="underline">{t("features.item_4.link_2")}</span>
                <ArrowRightSort className="w-[14px] h-[14px] pt-[2px] ml-[4px] group-hover:translate-x-1 transition-transform flex-shrink-0" />
              </a>
            </li>
            <li className="group">
              <a
                href="/products/trust-center"
                target="_blank"
                className="font-medium text-[#1890FF] leading-[22px]"
              >
                <span className="underline">{t("features.item_4.link_3")}</span>
                <ArrowRightSort className="w-[14px] h-[14px] pt-[2px] ml-[4px] group-hover:translate-x-1 transition-transform flex-shrink-0" />
              </a>
            </li>
          </ul>
        </div>
        <img
          src={
            router.locale === "en"
              ? "/images/home/features/SecurityCompliance.svg"
              : "/images/home/features/SecurityComplianceVi.svg"
          }
          className="w-full lg:max-w-full xl:max-w-[530px] max-w-[630px] aspect-3/2 object-cover"
          alt="CyStack Security Compliance"
        />
      </>
    );
  };
  const BlockchainSecurityContent = () => {
    return (
      <>
        <div className="flex flex-col justify-between flex-1 flex-shrink-0 p-8 pr-0 md:p-4">
          <div>
            <h3 className="text-dark-title text-[36px] lg:text-[28px] lg:leading-9 lg:mb-6 font-semibold leading-[48px] mb-12">
              {t("features.item_5.title")}
            </h3>
            <ul className="list-none flex flex-col gap-[10px] mb-8">
              <li className="group">
                <a
                  href="/services/blockchain-protocol-audit"
                  target="_blank"
                  className="font-medium text-[#1890FF] leading-[22px]"
                >
                  <span className="underline">
                    {t("features.item_5.link_1")}
                  </span>
                  <ArrowRightSort className="w-[14px] h-[14px] pt-[2px] ml-[4px] group-hover:translate-x-1 transition-transform flex-shrink-0" />
                </a>
              </li>
              <li className="group">
                <a
                  href="/services/smart-contract-audit"
                  target="_blank"
                  className="font-medium text-[#1890FF] leading-[22px]"
                >
                  <span className="underline">
                    {t("features.item_5.link_2")}
                  </span>
                  <ArrowRightSort className="w-[14px] h-[14px] pt-[2px] ml-[4px] group-hover:translate-x-1 transition-transform flex-shrink-0" />
                </a>
              </li>
              <li className="group">
                <a
                  href="https://safechain.org/"
                  target="_blank"
                  className="font-medium text-[#1890FF] leading-[22px]"
                >
                  <span className="underline">
                    {t("features.item_5.link_3")}
                  </span>
                  <ArrowRightSort className="w-[14px] h-[14px] pt-[2px] ml-[4px] group-hover:translate-x-1 transition-transform flex-shrink-0" />
                </a>
              </li>
            </ul>
          </div>
          <div className="flex items-center gap-6">
            <a
              href="https://cystack.net/customers/cellframe-achieve-succeed-in-the-blockchain-industry-with-the-power-of-crowdsourced-security"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image-blur">
                <img
                  src="/images/home/features/BlockchainSecurity-Logo1.svg"
                  className="max-h-[30px] object-contain"
                  alt="CyStack features"
                />
              </div>
            </a>
          </div>
        </div>
        <img
          src="/images/home/features/BlockchainSecurity.svg"
          className="w-full lg:max-w-full xl:max-w-[530px] max-w-[630px] aspect-3/2 object-cover"
          alt="CyStack Blockchain Security"
        />
      </>
    );
  };
  return (
    <>
      <section className="bg-[#ffffff] homepage-features">
        <div className="new-container border-b py-20 md:py-10 border-solid border-t-0 border-l-0 border-r-0 border-[#EAECF0]">
          <div className="max-w-[850px] leading-[85px] mx-auto text-center mb-12">
            <h2 className="text-[61px] leading-[75px] text-dark-title mb-6">
              {t("features.title")}
            </h2>
            <p className="text-body1 text-grey">{t("features.desc")}</p>
          </div>
          <Link href="/platform">
            <Button
              size="large"
              className="flex items-center gap-1 py-3 mx-auto text-white rounded-none text-body1sb px-9 bg-neutral-dark hover:border-neutral-dark"
            >
              <span>{t("features.button")}</span>
              <ArrowRight className="w-6 h-6 text-white" />
            </Button>
          </Link>
          <div className="mt-12 bg-white border border-solid border-[#F0F0F0] box-container">
            <nav className="flex items-center divide-x-[1px] divide-black border-b border-t-0 border-l-0 border-r-0 border-solid border-[#F0F0F0] lg:overflow-x-auto lg:overflow-y-hidden">
              {navbars.map((item, index) => (
                <div
                  key={item.key}
                  onClick={() => setActiveNavbar(item.key)}
                  className={`flex items-center justify-center whitespace-nowrap w-1/5 lg:w-fit lg:px-4 gap-2 py-6 cursor-pointer transition-colors nav-item relative ${
                    activeNavbar === item.key
                      ? "is-active text-dark-title font-medium"
                      : "text-dark-secondary"
                  }`}
                >
                  <div
                    className={`absolute right-0 top-4 bottom-4 w-[1px] bg-[#D9D9D9] ${
                      index === navbars.length - 1 ? "hidden" : ""
                    }`}
                  ></div>
                  {item.icon}
                  <span>{item.title}</span>
                </div>
              ))}
            </nav>
            {activeNavbar === "security_audit" ? (
              <div className="flex gap-6 content lg:flex-col animate-fade">
                {SecurityAuditContent()}
              </div>
            ) : null}
            {activeNavbar === "security_operations" ? (
              <div className="flex gap-6 content lg:flex-col animate-fade">
                {SecurityOperationsContent()}
              </div>
            ) : null}
            {activeNavbar === "data_security" ? (
              <div className="flex gap-6 content lg:flex-col animate-fade">
                {DataSecurityContent()}
              </div>
            ) : null}
            {activeNavbar === "security_compliance" ? (
              <div className="flex gap-6 content lg:flex-col animate-fade">
                {SecurityComplianceContent()}
              </div>
            ) : null}
            {activeNavbar === "blockchain_security" ? (
              <div className="flex gap-6 content lg:flex-col animate-fade">
                {BlockchainSecurityContent()}
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </>
  );
}
